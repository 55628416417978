.headerContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 8px 0;
    box-shadow: 5px 5px 8px rgb(100 100 100 / 30%);
    z-index: 1;
    top:0px;
    width: 100%;
    color: black;
}
.headerContainer img{
    height: 100px
}

/* Other */
.gradient{
    letter-spacing: 0px;
    background: linear-gradient(2deg,var(--sec-color),var(--main-color)),var(--main-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 300%;
    animation: gradient-flow 8s ease-in-out infinite;
    -webkit-animation: gradient-flow 8s ease-in-out infinite;
    color: transparent;
    margin: 0;
  }
  /* Animation */
  .animationModule{
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
  }
  
  @-webkit-keyframes gradient-flow {
    0% {
        background-position:0 50%
    }
  
    50% {
        background-position:100% 50%
    }
  
    to {
        background-position:0 50%
    }
  }
  
  @keyframes gradient-flow {
    0% {
        background-position:0 50%
    }
  
    50% {
        background-position:100% 50%
    }
  
    to {
        background-position:0 50%
    }
  }
  