@import url("https://fonts.googleapis.com/css?family=Mulish:regular,bold,italic,black");

:root {
    --main-color: #1B849B;
    --main-transparent:rgba(20, 35, 76, 0.9);
    --main-more-transparent:rgba(20, 35, 76, 0.3);
    --sec-color: #FF994B;
    --tri-color: #00AAA1;
  }

body {
    margin: 0;
    font-family: 'Mulish',Helvetica,Arial,Lucida,sans-serif;
    font-size: 18px;
    line-height: 1.44em;
    font-weight: 400;
    color: var(--main-color);
    background-color: #000;
    overflow-x: hidden;
    -webkit-font-smoothing:antialiased
}

p {
    color: var(--main-color);
    font-family: 'Mulish',Helvetica,Arial,Lucida,sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    margin-top: 20px;
    margin-bottom: 20px;
}

h1, h2, h3, h4, h5, h6 {
    margin:0;
}

h1 {
    font-family: 'Mulish',Helvetica,Arial,Lucida,sans-serif;
    font-size: 50px;
    line-height: 70px;
    font-weight: 900;
}
h2 {
    color: var(--main-color);
    font-family: 'Mulish',Helvetica,Arial,Lucida,sans-serif;
    font-size: 40px;
    line-height: 50px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
}

h3 {
    color: var(--main-color);
    font-family: 'Mulish',Helvetica,Arial,Lucida,sans-serif;
    font-size: 34px;
    line-height: 45px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
}

h4 {
    font-family: 'Mulish',Helvetica,Arial,Lucida,sans-serif;
    font-size: 25px;
    line-height: 35px;
    font-weight: 700;
    text-transform: uppercase;
}

h5 {
    font-family: 'Mulish',Helvetica,Arial,Lucida,sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
}

h6 {
    color: var(--main-color);
    font-family: 'Mulish',Helvetica,Arial,Lucida,sans-serif;
    font-size: 13px;
    font-style: normal;
    text-transform: uppercase;
}

a, p a {
    font-style: normal;
    font-weight: 400;
    color: var(--main-color);
    text-decoration: none;
}

a:hover, p a:hover {
    color: var(--sec-color);
    text-decoration: underline;
}

.centered-items{
    text-align: center;
}
.importanttext{
    color: var(--main-color);
}
/* Main */
.content{
  margin-top: 32px;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  text-align: center;
}

/* Card */
.cards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly
}
.card{
  background-color: white;
  box-shadow: 5px 5px 8px rgb(100 100 100 / 50%), 2px 2px 5px rgb(100 100 100 / 10%);
  margin: 0 auto 32px;
  width: 40vw;
  border-radius: 8px;
  overflow: hidden;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  background-position: center;
  background-size: cover;
}
.card h5, .card h6{
  margin: 4px;
}
.titleArea{
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 80px;
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.bottomText{
  background-color: var(--main-color);
  color: white;
  margin: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottomText h6{
  margin: 8px;
  color: white;
  text-align: center;
}
.bottomText p{
  color: white;
  margin: 0px;
}
.pollContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.actionButton{
  background-color: white;
  box-shadow: 2px 3px 6px rgb(100 100 100 / 50%);
  text-transform: uppercase;
  font-size: 2vh;
  font-weight: 700;
  min-width: 80%;
  min-height: 5vh;
  border-radius: 10px;
  border: 0;
  cursor: pointer;
  margin: 8px;
  color: var(--main-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
}
.actionButton caption{
  text-align: center;
  font-weight: 100;
}
.program{
  background-image: url("./img/Stadium-BG-Blank.png");
  aspect-ratio: 2;
}
.raffle{
  background-image: url("./img/az5050.png");
  aspect-ratio: 2;
}
.bowlbash{
  background-image: url("./img/bowlbash.png");
  aspect-ratio: 2;
}
.restaurants{
  background-image: url("./img/restaurants.png");
  aspect-ratio: 2;
}
.poll{
  color: white;
  background-size: 300%;
  animation: gradient-flow 8s ease-in-out infinite;
  -webkit-animation: gradient-flow 8s ease-in-out infinite;
}
.poll h6{
  line-height: 16px;
}
.poll h6, .poll h3{
  text-align: center;
  margin: 4px;
}
.poll .titleArea{
  flex-shrink: 1
}
.poll .bottomText{
  flex-grow: 1;
}
.pollContainer{
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content:space-around;
}
.pollContainer button{
  font-size: 14px;
  margin: 4px;
}
.pollContainer button caption{
  font-size: 12px;
}

/* Sizing */
@media only screen and (max-width: 900px) {
  /* .card {
    width:60vw;
  } */
   .card{
    margin-bottom: 16px;
    margin-left: 8px;
    margin-right: 8px;
   }
  h1 {
      font-size: 45px !important;
      line-height:55px !important
  }

  h2 {
      font-size: 35px !important;
      line-height:45px !important
  }

  h3 {
      font-size: 30px !important;
      line-height:40px !important
  }
  h6{
    font-size: 12px;
    line-height: 14px;
  }
  .actionButton{
    font-size: 14px;
    line-height: 16px;
    min-height: 0;
    text-wrap:nowrap;
  }
}
@media only screen and (max-width: 720px) {
  h1 {
      font-size: 35px !important;
      line-height:45px !important
  }

  h2 {
      font-size: 27px !important;
      line-height:35px !important
  }

  h3 {
      font-size: 20px !important;
      line-height:24px !important
  }
  .bottomText{
    flex-direction: column;
  }
  .bottomText h6{
    font-size: 12px;
    line-height: 14px;
  }
  .pollContainer .actionButton{
    text-wrap:pretty;
  }
}
@media only screen and (max-width: 450px) {
  h1 {
      font-size: 28px !important;
      line-height:30px !important
  }

  h2 {
      font-size: 20px !important;
      line-height:22px !important
  }

  h3 {
      font-size: 14px !important;
      line-height:16px !important
  }
  .bottomText{
    flex-direction: column;
  }
  .bottomText h6{
    margin: 4px;
    font-size: 10px;
    line-height: 12px;
  }
  .pollContainer button caption{
    font-size: 6px;
    line-height: 8px;
  }
  .pollContainer .actionButton{
    text-wrap:pretty;
    font-size: 10px;
    line-height: 12px;
  }
  .actionButton{
    font-size: 10px;
    line-height: 12px;
    min-height: 0;
    text-wrap:nowrap;
    margin: 2px;
  }
}
